import {Authenticator, useTheme, View} from '@aws-amplify/ui-react'
import {Amplify} from 'aws-amplify'
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'
import awsExports from './aws-exports'
import AdminApp from './components/AdminApp'
import CloudFrontDistributionForm from './components/CloudFrontDistributionForm'
import CloudFrontDistributionList from './components/CloudFrontDistributionList'
import Home from './components/Home'
import JsonSchemaForm from './components/JsonSchemaForm'
import JsonSchemaList from './components/JsonSchemaList'
import JsonSchemaUiForm from './components/JsonSchemaUiForm'
import NotFound from './components/NotFound'
import MachineForm from './components/MachineForm'
import MachineList from './components/MachineList'
import PageForm from './components/PageForm'
import PageList from './components/PageList'
import RankingForm from './components/RankingForm'
import RankingList from './components/RankingList'
import RankingSiteServiceForm from './components/RankingSiteServiceForm'
import ReviewForm from './components/ReviewForm'
import ReviewList from './components/ReviewList'
import ServiceForm from './components/ServiceForm'
import ServiceList from './components/ServiceList'
import ShopAccessForm from './components/ShopAccessForm'
import ShopForm from './components/ShopForm'
import ShopList from './components/ShopList'
import SiteServiceForm from './components/SiteServiceForm'
import SiteServiceList from './components/SiteServiceList'
import '@aws-amplify/ui-react/styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'

Amplify.configure(awsExports)

const components = {
  Header() {
    const {tokens} = useTheme()

    return (
      <View textAlign="center" padding={tokens.space.large}>
      </View>
    )
  }
}

const App = () => {
  return (
    <Authenticator
      components={components}
      hideSignUp={true}
      loginMechanisms={['email']}
    >
      {({signOut, user}) => (
        <BrowserRouter basename="/admin-v1">
          <Routes>
            <Route path="/" element={<AdminApp signOut={signOut} user={user}/>}>
              <Route index element={<Home />}/>
              <Route path="cloud-front-distributions" element={<CloudFrontDistributionList />}/>
              <Route path="cloud-front-distributions/create" element={<CloudFrontDistributionForm />}/>
              <Route path="cloud-front-distributions/:id" element={<CloudFrontDistributionForm />}/>
              <Route path="json-schemas" element={<JsonSchemaList />}/>
              <Route path="json-schemas/:id" element={<JsonSchemaForm />}/>
              <Route path="json-schemas/create" element={<JsonSchemaForm />}/>
              <Route path="json-schemas/:id/uis" element={<JsonSchemaUiForm />}/>
              <Route path="machines" element={<MachineList />}/>
              <Route path="machines/create" element={<MachineForm />}/>
              <Route path="machines/:id" element={<MachineForm />}/>
              <Route path="pages" element={<PageList />}/>
              <Route path="pages/create" element={<PageForm />}/>
              <Route path="pages/:id" element={<PageForm />}/>
              <Route path="rankings" element={<RankingList />}/>
              <Route path="rankings/create" element={<RankingForm />}/>
              <Route path="rankings/:id" element={<RankingForm />}/>
              <Route path="rankings/:id/site-services" element={<RankingSiteServiceForm />}/>
              <Route path="reviews" element={<ReviewList />}/>
              <Route path="reviews/create" element={<ReviewForm />}/>
              <Route path="reviews/:id" element={<ReviewForm />}/>
              <Route path="services/:id" element={<ServiceForm />}/>
              <Route path="services/create" element={<ServiceForm />}/>
              <Route path="services" element={<ServiceList />}/>
              <Route path="shops" element={<ShopList />}/>
              <Route path="shops/create" element={<ShopForm />}/>
              <Route path="shops/:id" element={<ShopForm />}/>
              <Route path="shops/:id/accesses" element={<ShopAccessForm />}/>
              <Route path="site-services/:id" element={<SiteServiceForm />}/>
              <Route path="site-services/create" element={<SiteServiceForm />}/>
              <Route path="site-services" element={<SiteServiceList />}/>
            </Route>
            <Route path="*" element={<NotFound />}/>
          </Routes>
        </BrowserRouter>
      )}
    </Authenticator>
  )
}

export default App
