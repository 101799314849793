import {Storage, Auth} from 'aws-amplify'
import {useEffect, useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import ErrorMessage from './ErrorMessage'
import {parseErrors} from '../../api'

const exts = {
  'image/gif': 'gif',
  'image/jpeg': 'jpg',
  'image/png': 'png'
}

const ImageField = ({disabled, name, onChange, value, ...rest}) => {
  const [imageUrl, setImageUrl] = useState()
  const [errors, setErrors] = useState()

  const handleChange = async e => {
    setErrors()

    try {
      const file = e.target.files[0]
      console.log(file.type)
      if(!['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml'].includes(file.type)) {
        return setErrors([{message: 'ファイルタイプは gif, jpg, png のみです。'}])
      }

      const now = new Date()

      const ext = exts[file.type]

      const filename = [
        'uploads',
        now.getFullYear().toString(),
        (now.getMonth() + 1).toString(),
        now.getDate().toString(),
        `${uuidv4()}.${ext}`,
      ].join('/')

      console.log( Auth.configure() )

      const r = await Storage.put(filename, file, {
        contentType: file.type, // contentType is optional
        customPrefix: {
           public: 'prod/'
        }
      })

      onChange({target: {name, value: '/' + r.key}})
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  useEffect(() => {
    if(!value) {
      return
    }

    let url

    try {
      url = new URL(value)
    } catch (_) {
      url = new URL(`https://${process.env.REACT_APP_CDN_HOST}` + value)
    }

    setImageUrl(url)
  }, [value])

  return (
    <div>
      {(errors && errors.length > 0) &&
        <ErrorMessage errors={errors}/>
      }
      <input
        accept="image/gif image/jpeg image/png image/svg+xml"
        disabled={disabled === true}
        name={name}
        onChange={handleChange}
        type="file"
      />
      {imageUrl &&
        <img alt="画像が読み込めません" className="mt-4" src={imageUrl} style={{maxWidth: '100%'}}/>
      }
    </div>
  )
}

export default ImageField
