import {useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import {useNavigate, useParams} from 'react-router-dom'
import ErrorMessage from './ui/ErrorMessage'
import FormContainer from './ui/FormContainer'
import Headline from './ui/Headline'
import SuccessMessage from './ui/SuccessMessage'
import {parseErrors} from '../api'
import {
  createJsonSchemaUis,
  readJsonSchema,
  readJsonSchemaUis,
  updateJsonSchemaUis
} from '../api/json-schemas'
import {UI_TYPES_JA} from '../constants/json-schemas'

// import UiForm from './UiForm'
// import AppContainer from '../ui/AppContainer'
// import Breadcrumb from '../ui/Breadcrumb'
// import ErrorMessage from '../ui/ErrorMessage'
// import Head from '../ui/Head'
// 

// const initialBreadcrumbs = [
//   {title: 'JSON Schema 設計', to: `/json-schemas`}
// ]

const INITIAL_FORM_VALUES = {
  json_schema_id: '',
  json_schema_key: '',
  ui_type: ''
}

const JsonSchemaUiForm = () => {
  const [errors, setErrors] = useState()
  const [formValues, setFormValues] = useState()
  const [properties, setProperties] = useState()
  const [successMessage, setSuccessMessage] = useState()

  let navigate = useNavigate()
  const {id} = useParams()

  const handleChange = index => e => {
    const {target: {name, value}} = e

    const newFormValues = {...formValues[index], [name]: value}
    setFormValues(formValues.map((item, i) => i == index ? newFormValues : item))
  }

  const handleSubmit = index => async e => {
    e.preventDefault()
    setSuccessMessage()

    const v = formValues[index]

    try {
      let r
      if(v.id) {
        r = await updateJsonSchemaUis(id, v, v.id)
      }
      else {
        r = await createJsonSchemaUis(id, v)  
      }

      setSuccessMessage('保存しました。')
      
      // navigate('/json-schemas', {replace: true})
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  const initForm = async id => {
    try {
      const rs = await Promise.all([
        readJsonSchema(id),
        readJsonSchemaUis(id),
      ])

      const newProperties = JSON.parse(rs[0].json_schema).properties
      setProperties(newProperties)

      let formValuesMap = {}
      rs[1].data.map(item => {
        formValuesMap[item.json_schema_key] = item
      })

      const newFormValues = Object.keys(newProperties).map(k => {
        return formValuesMap.hasOwnProperty(k) ? 
          formValuesMap[k] : {...INITIAL_FORM_VALUES, ...{
            json_schema_key: k,
            json_schema_id: id
          }}
      })

      setFormValues(newFormValues)
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  useEffect(() => {
    initForm(id)
  }, [id])

  return (
    <div>
      <Headline>JSON Schema UI {id ? '編集' : '追加'}</Headline>
      <ErrorMessage errors={errors}/>
      {successMessage && <SuccessMessage message={successMessage}/>}
      {Array.isArray(formValues) &&
        <FormContainer>
          {formValues.map((item, i) => {
            const {json_schema_key} = item
            return (
              <Form onSubmit={handleSubmit(i)} key={i}>
                <Form.Group className="mb-3">
                  <Form.Label>{properties[json_schema_key].description}</Form.Label>
                  <InputGroup>
                    <Form.Select name="ui_type" onChange={handleChange(i)} value={item.ui_type}>
                      <option value="">未設定</option>
                      {Object.keys(UI_TYPES_JA).map(k => (
                        <option key={k} value={k}>{UI_TYPES_JA[k]}</option>
                      ))}
                    </Form.Select>
                    <Button type="submit" variant="primary">保存</Button>
                  </InputGroup>
                </Form.Group>
              </Form>
            )
          })}
        </FormContainer>
      }
    </div>
  )
  // const [breadcrumbs, setBreadcrumbs] = useState([])
  // const [errors, setErrors] = useState()
  // 
  // 

  // 

  // const updateJsonSchemaUis = async () => {
  //   

  //   let err
  //   if(!r[0].success) {
  //     err = r[0].errors
  //   }

  //   if(!r[1].success) {
  //     err = r[1].errors
  //   }

  //   if(err) {
  //     return setErrors(err)
  //   }

  //   setJsonSchema(r[0].response)
  //   setJsonSchemaUis(r[1].response.data)
  // }

  // const updateJsonSchema = async () => {
  //   const r = await readJsonSchema(id)
  //   if(r.success) {
  //     return setJsonSchema(r.response)
  //   }
    
  // }

  // useEffect(() => {
  //   setBreadcrumbs(initialBreadcrumbs.concat([{title: `${id}の UI 編集`}]))
  //   updateJsonSchemaUis()
  // }, [id])

  // return (
  //   <AppContainer>
  //     <Head title="JSON Schema 設計 UI 編集"/>
  //     <Breadcrumb breadcrumbs={breadcrumbs}/>
  //     {errors &&
  //       <ErrorMessage errors={errors}/>
  //     }
  //     {(siteLoanSchema && siteLoanSchemaUis) &&
  //       <UiForm schema={siteLoanSchema} values={siteLoanSchemaUis}/>
  //     }
  //   </AppContainer>
  // )
}

export default JsonSchemaUiForm

// import LoadingButton from '@mui/lab/LoadingButton';
// import Card from '@mui/material/Card'
// import CardHeader from '@mui/material/CardHeader'
// import CardContent from '@mui/material/CardContent'
// import MenuItem from '@mui/material/MenuItem'
// import React, {useEffect, useState} from 'react'
// import {useHistory, useParams} from 'react-router-dom'
// import ErrorMessage from '../ui/ErrorMessage'
// import FormContainer from '../ui/FormContainer'
// import TextField from '../ui/TextField'
// import {batchUpsertJsonSchemaUis} from '../../api/json-schemas'
// import {
//   OBJECT_TYPE_SITE_LOAN, UI_TYPE_RANKING, UI_TYPE_SITE_LOAN, UI_TYPES_JA
// } from '../../constants/json-schemas'

// const defaultValue = {
//   json_schema_id: '',
//   name: '',
//   ui_type: ''
// }

// const UiForm = ({schema, values: initialValues}) => {
//   if(!schema) {
//     return null
//   }

//   const [errors, setErrors] = useState([])
//   const [isLoading, setIsLoading] = useState(false)
//   const [properties, setProperties] = useState()
//   const [uiTypeKeys, setUiTypeKeys] = useState([])
//   const [values, setValues] = useState(values)

//   const history = useHistory()
//   const {id} = useParams()

//   

//   const updateValues = () => {
//     let valuesMap = {}
//     initialValues.map(item => {
//       valuesMap[item.name] = item
//     })

//     const newValues = Object.keys(properties).map(k => {
//       return valuesMap.hasOwnProperty(k) ? valuesMap[k] : {...defaultValue, ...{
//         name: k,
//         json_schema_id: schema.id
//       }}
//     })

//     setValues(newValues)
//   }

//   useEffect(() => {
//     setProperties(JSON.parse(schema.json_schema).properties)
//   }, [schema])

//   useEffect(() => {
//     if(Array.isArray(initialValues) && properties) {
//       updateValues()  
//     }
//   }, [properties])

//   useEffect(() => {
//     const newUiTypeKeys = Object.keys(UI_TYPES_JA).filter(k => 
//       schema.object_type != OBJECT_TYPE_SITE_LOAN || ![UI_TYPE_RANKING, UI_TYPE_SITE_LOAN].includes(k)
//     )
//     setUiTypeKeys(newUiTypeKeys)
//   }, [schema.object_type])

//   if(!Array.isArray(values)) {
//     return null
//   }

//   return (
//     <FormContainer>
//       {(errors && errors.length > 0) &&
//         <ErrorMessage errors={errors} k="dataset"/>
//       }
//       <form onSubmit={handleSubmit}>
//         {values.map((item, i) => {
//           const {name} = item
//           const {description} = properties[name]

//           return (
//             <Card elevation={3} key={i} sx={{mb: 2}}>
//               <CardHeader title={description}/>
//               <CardContent>
//                 <TextField
//                   errors={errors}
//                   fullWidth
//                   index={i}
//                   label={!item.ui_type ? 'UI を設定' : 'UI'}
//                   onChange={handleChange(i)}
//                   name="ui_type"
//                   value={item.ui_type}
//                   select
//                   sx={{mb: 2}}
//                 >
//                   <MenuItem value="">未設定</MenuItem>
//                   {uiTypeKeys.map(k => (
//                     <MenuItem key={k} value={k}>{UI_TYPES_JA[k]}</MenuItem>
//                   ))}
//                 </TextField>
//               </CardContent>
//             </Card>
//           )
//         })}

//         <LoadingButton
//           fullWidth
//           loading={isLoading}
//           type="submit"
//           variant="contained"
//         >
//           保存
//         </LoadingButton>
//       </form>
//     </FormContainer>
//   )
// }

// export default UiForm
