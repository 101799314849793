const ErrorMessage = ({errors, errorKey, index}) => {
  if(!Array.isArray(errors)) {
    return null
  }

  let error
  for(let e of errors) {
    if(((errorKey && e.key === errorKey) || (!errorKey && !e.key)) && (!index || index === e.index)) {
      error = e
      break
    }
  }

  if(!error) {
    return null
  }

  return (
    <div className="text-danger">{error.message}</div>
  )
}

export default ErrorMessage
