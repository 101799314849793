import {API} from 'aws-amplify'
import {DEFAULT_LIMIT, getAuthorizationHeader, getVersion} from '../'

// const createTag = async body => {
//   const headers = await getAuthorizationHeader()

//   return API.post('datsururin', `v1/tags`, {
//     body,
//     headers,
//     response: true
//   })
// }

// const readTag = async id => {
//   const headers = await getAuthorizationHeader()
  
//   return API.get('datsururin', `v1/tags/${id}`, {
//     headers
//   })
// }

const readTags = async ({fields, page, limit = DEFAULT_LIMIT}) => {
  const headers = await getAuthorizationHeader()

  const queryStringParameters = {
    order: 'DESC',
    orderby: 'id',
    limit,
    page,
    v: getVersion()
  }
  if(fields) {
    queryStringParameters.fields = fields
  }

  return API.get('datsururin', 'v1/tags', {
    headers, queryStringParameters
  })
}

// const updateTag = async (id, body) => {
//   const headers = await getAuthorizationHeader()

//   return API.put('datsururin', `v1/tags/${id}`, {
//     body,
//     headers,
//     response: true
//   })
// }

// const createTagsJsonSchema = async (id, body) => {
//   const headers = await getAuthorizationHeader()

//   return API.post('datsururin', `v1/tags/${id}/json-schemas`, {
//     body,
//     headers,
//     response: true
//   })
// }

// const updateTagsJsonSchema = async (id, body, tagsJsonSchemaId) => {
//   const headers = await getAuthorizationHeader()

//   return API.put('datsururin', `v1/tags/${id}/json-schemas/${tagsJsonSchemaId}`, {
//     body,
//     headers,
//     response: true
//   })
// }

export {
  // createTag,
  // readTag,
  readTags,
  // updateTag,
  // createTagsJsonSchema,
  // updateTagsJsonSchema,
}
