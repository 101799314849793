import {API} from 'aws-amplify'
import {DEFAULT_LIMIT, getAuthorizationHeader, getVersion} from '../'

const createShop = async body => {
  const headers = await getAuthorizationHeader()

  return API.post('datsururin', `v1/shops`, {
    body,
    headers,
    response: true
  })
}

const readShop = async id => {
  const headers = await getAuthorizationHeader()
  
  return API.get('datsururin', `v1/shops/${id}?v=${getVersion()}`, {
    headers
  })
}

const readShops = async ({fields, isAvailable, limit = DEFAULT_LIMIT, page, serviceId = null}) => {
  const headers = await getAuthorizationHeader()

  let queryStringParameters = {
    is_available: isAvailable,
    order: 'DESC',
    orderby: 'id',
    limit,
    page,
    v: getVersion(),
  }
  if(fields) {
    queryStringParameters.fields = fields
  }
  if(serviceId) {
    queryStringParameters.service_id = serviceId
  }

  return API.get('datsururin', 'v1/shops', {
    headers, queryStringParameters
  })
}

const updateShop = async (id, body) => {
  const headers = await getAuthorizationHeader()

  return API.put('datsururin', `v1/shops/${id}`, {
    body,
    headers,
    response: true
  })
}

const readShopAccesses = async shopId => {
  const headers = await getAuthorizationHeader()

  const queryStringParameters = {}

  return API.get('datsururin', `v1/shops/${shopId}/accesses?v=${getVersion()}`, {
    headers, queryStringParameters
  })
}

const batchUpsertShopAccesses = async (shopId, body) => {
  const headers = await getAuthorizationHeader()

  return API.post('datsururin', `v1/shops/${shopId}/accesses`, {
    body,
    headers,
    response: true
  })
}

export {
  createShop,
  readShop,
  readShops,
  updateShop,
  readShopAccesses,
  batchUpsertShopAccesses,
}
