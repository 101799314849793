import {useEffect, useState}  from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {useNavigate, useParams} from 'react-router-dom'
import ErrorMessage from './ui/ErrorMessage'
import FormContainer from './ui/FormContainer'
import Headline from './ui/Headline'
import JsonSchemaDataListForm from './ui/JsonSchemaDataListForm'
import {parseErrors} from '../api'
import {createService, readService, updateService} from '../api/services'
import {readTags} from '../api/tags'
import {DATA_TYPE_SERVICE} from '../constants/json-schemas'

const INITIAL_FORM_VALUES = {
  name: '',
  slug: '',
  // json_schema_id: '',
  // json_schema_data: ''
  tag_ids: []
}

const ServiceForm = () => {
  const [errors, setErrors] = useState()
  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES)
  const [isReady, setIsReady] = useState(false)
  const [tags, setTags] = useState([])

  let navigate = useNavigate()
  const {id} = useParams()

  const handleChange = e => {
    const {target: {checked, name, value}} = e

    let v = value

    if(name == 'tag_ids') {
      let tagIds = formValues.tag_ids || []

      let newTagIds
      if(checked === true) {
        newTagIds = tagIds.concat([value])
      }
      else {
        newTagIds = tagIds.filter(item => item != value)
      }

      v = newTagIds
    }
    setFormValues({...formValues, [name]: v})
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      if(id) {
        await updateService(id, formValues)
      }
      else {
        await createService(formValues)
      }
      navigate('/services', {replace: true})
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  const initForm = async id => {
    try {
      let newFormValues
      if(!id) {
        newFormValues = INITIAL_FORM_VALUES
      }
      else {
        const r = await readService(id)
        newFormValues = r 
        newFormValues.tag_ids = r.tags.map(item => item.id)
      }
      setFormValues(newFormValues)

      const tags = await readTags({limit: -1})
      setTags(tags.data)
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
    finally {
      setIsReady(true)
    }
  }

  useEffect(() => {
    initForm(id)
  }, [id])

  return (
    <div>
      <Headline>サービス {id ? '編集' : '追加'}</Headline>
      {isReady &&
        <FormContainer>
          <ErrorMessage errors={errors}/>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>名前</Form.Label>
              <Form.Control
                name="name"
                onChange={handleChange}
                placeholder="銀座クリニック"
                type="text"
                value={formValues.name}
              />
              <ErrorMessage errors={errors} errorKey="name"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>検索用タグ</Form.Label>
              {tags.map(item =>
                <Form.Check 
                  checked={(formValues.tag_ids || []).some(id => id.toString() === item.id.toString())}
                  id={`tag-${item.id}`}
                  key={item.id}
                  name="tag_ids"
                  label={item.name}
                  onChange={handleChange}
                  type="checkbox"
                  value={item.id}
                />
              )}
            </Form.Group>
            <div className="d-grid gap-2">
              <Button type="submit" variant="primary">サービス保存</Button>
            </div>
          </Form>
          {id &&
            <Form className="mt-4">
              <JsonSchemaDataListForm
                dataType={DATA_TYPE_SERVICE}
                id={id}
                values={formValues.json_schemas}
              />
            </Form>
          }
        </FormContainer>
      }
    </div>
  )
}

export default ServiceForm
