import {API} from 'aws-amplify'
import {DEFAULT_LIMIT, getAuthorizationHeader, getVersion} from '../'

const createCloudFrontDistribution = async body => {
  const headers = await getAuthorizationHeader()

  return API.post('datsururin', `v1/cloud-front-distributions`, {
    body,
    headers,
    response: true
  })
}

const invalidateCloudFrontDistribution = async body => {
  const headers = await getAuthorizationHeader()

  return API.post('datsururin', `v1/cloud-front-distributions/invalidate`, {
    body,
    headers,
    response: true
  })
}

const readCloudFrontDistribution = async id => {
  const headers = await getAuthorizationHeader()
  
  return API.get('datsururin', `v1/cloud-front-distributions/${id}?v=${getVersion()}`, {
    headers
  })
}

const readCloudFrontDistributions = async ({fields, ids, page, limit = DEFAULT_LIMIT}) => {
  const headers = await getAuthorizationHeader()

  let queryStringParameters = {
    order: 'DESC',
    orderby: 'id',
    limit,
    page,
    v: getVersion(),
  }
  if(fields) {
    queryStringParameters.fields = fields
  }
  if(ids) {
    queryStringParameters.ids = ids
  }

  return API.get('datsururin', 'v1/cloud-front-distributions', {
    headers, queryStringParameters
  })
}

const updateCloudFrontDistribution = async (id, body) => {
  const headers = await getAuthorizationHeader()

  return API.put('datsururin', `v1/cloud-front-distributions/${id}`, {
    body,
    headers,
    response: true
  })
}

export {
  createCloudFrontDistribution,
  invalidateCloudFrontDistribution,
  readCloudFrontDistribution,
  readCloudFrontDistributions,
  updateCloudFrontDistribution,
}
