const readSites = async () => {
  return Promise.resolve({
    count: 1,
    data: [
      {id: 103, name: 'だつるりん'},
    ],
    limit: 0,
    page: 1,
    pages: 1,
  })
}

export {
  readSites,
}
