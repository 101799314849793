import {useEffect, useState} from 'react'
import Table from 'react-bootstrap/Table'
import {Link, useSearchParams} from 'react-router-dom'
import ErrorMessage from './ui/ErrorMessage'
import Datetime from './ui/Datetime'
import Headline from './ui/Headline'
import InvalidationForm from './ui/InvalidationForm'
import Pagination from './ui/Pagination'
import {parseErrors} from '../api'
import {readServices} from '../api/services'

const ServiceList = ({user}) => {
  const [errors, setErrors] = useState([])
  const [page, setPage] = useState()
  const [pagination, setPagination] = useState({})

  const [searchParams] = useSearchParams()

  const paginate = async page => {
    const r = await readServices({
      fields: ['id', 'name', 'created_at', 'updated_at'],
      page: page || 1, 
    })
    try {
      setPagination(r)
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  useEffect(() => {
    let newPage = Number(searchParams.get('page'))
    if(isNaN(newPage) || newPage === 0) {
      newPage = 1
    }
    setPage(newPage)

    paginate(newPage)
  }, [searchParams])

  return (
    <div>
      <Headline>共通サービス一覧</Headline>
      <InvalidationForm/>
      <ErrorMessage errors={errors}/>
      {pagination.data &&
        <Table striped bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>名前</th>
              <th>作成日時</th>
              <th>更新日時</th>
              <th>アクション</th>
            </tr>
          </thead>
          <tbody>
            {pagination.data.map(item => 
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td><Datetime date={item.created_at}/></td>
                <td><Datetime date={item.updated_at}/></td>
                <td><Link to={`/services/${item.id}`}>編集</Link></td>
              </tr>
            )}
          </tbody>
        </Table>
      }
      <Pagination page={page} pages={pagination.pages}/>
    </div>
  )
}

export default ServiceList
