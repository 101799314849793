import {API} from 'aws-amplify'
import {DEFAULT_LIMIT, getAuthorizationHeader, getVersion} from '../'

const createJsonSchema = async body => {
  const headers = await getAuthorizationHeader()

  return API.post('datsururin', `v1/json-schemas`, {
    body,
    headers,
    response: true
  })
}

const readJsonSchema = async id => {
  const headers = await getAuthorizationHeader()
  
  return API.get('datsururin', `v1/json-schemas/${id}?v=${getVersion()}`, {
    headers
  })
}

const readJsonSchemas = async ({dataType, fields, page, limit = DEFAULT_LIMIT}) => {
  const headers = await getAuthorizationHeader()

  let queryStringParameters = {
    order: 'DESC',
    orderby: 'id',
    limit,
    page,
    v: getVersion(),
  }
  if(fields) {
    queryStringParameters.fields = fields
  }
  if(dataType) {
    queryStringParameters.data_type = dataType
  }

  return API.get('datsururin', 'v1/json-schemas', {
    headers, queryStringParameters
  })
}

const updateJsonSchema = async (id, body) => {
  const headers = await getAuthorizationHeader()

  return API.put('datsururin', `v1/json-schemas/${id}`, {
    body,
    headers,
    response: true
  })
}

const readJsonSchemaUis = async (jsonSchemaId, page, limit = 0) => {
  const headers = await getAuthorizationHeader()

  const queryStringParameters = {
    order: 'DESC',
    orderby: 'id',
    limit,
    page,
    v: getVersion(),
  }

  return API.get('datsururin', `v1/json-schemas/${jsonSchemaId}/uis`, {
    headers, queryStringParameters
  })
}

const createJsonSchemaUis = async (jsonSchemaId, body) => {
  const headers = await getAuthorizationHeader()

  return API.post('datsururin', `v1/json-schemas/${jsonSchemaId}/uis`, {
    body,
    headers,
    response: true
  })
}

const updateJsonSchemaUis = async (jsonSchemaId, body, jsonSchemaUiId) => {
  const headers = await getAuthorizationHeader()

  return API.put('datsururin', `v1/json-schemas/${jsonSchemaId}/uis/${jsonSchemaUiId}`, {
    body,
    headers,
    response: true
  })
}

const batchUpsertJsonSchemaUis = async (jsonSchemaId, body) => {
  const headers = await getAuthorizationHeader()

  return API.post('datsururin', `v1/json-schemas/${jsonSchemaId}/uis`, {
    body,
    headers,
    response: true
  })
}

export {
  createJsonSchema,
  readJsonSchema,
  readJsonSchemas,
  updateJsonSchema,
  readJsonSchemaUis,
  batchUpsertJsonSchemaUis,
  createJsonSchemaUis,
  updateJsonSchemaUis,
}
