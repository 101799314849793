import {API} from 'aws-amplify'
import {DEFAULT_LIMIT, getAuthorizationHeader, getVersion} from '../'

const createRanking = async body => {
  const headers = await getAuthorizationHeader()

  return API.post('datsururin', `v1/rankings`, {
    body,
    headers,
    response: true
  })
}

const readRanking = async id => {
  const headers = await getAuthorizationHeader()
  
  return API.get('datsururin', `v1/rankings/${id}?v=${getVersion()}`, {
    headers
  })
}

const readRankings = async ({fields, page, limit = DEFAULT_LIMIT, siteId = null}) => {
  const headers = await getAuthorizationHeader()

  let queryStringParameters = {
    order: 'DESC',
    orderby: 'id',
    limit,
    page,
    v: getVersion(),
  }
  if(fields) {
    queryStringParameters.fields = fields
  }
  if(siteId) {
    queryStringParameters.site_id = siteId
  }

  return API.get('datsururin', 'v1/rankings', {
    headers, queryStringParameters
  })
}

const updateRanking = async (id, body) => {
  const headers = await getAuthorizationHeader()

  return API.put('datsururin', `v1/rankings/${id}`, {
    body,
    headers,
    response: true
  })
}

const readRankingsSiteServices = async rankingId => {
  const headers = await getAuthorizationHeader()

  const queryStringParameters = {
    order: 'DESC',
    orderby: 'priority',
    limit: -1,
    include_json_schemas: false,
    v: getVersion(),
  }

  return API.get('datsururin', `v1/rankings/${rankingId}/site-services`, {
    headers, queryStringParameters
  })
}

const batchUpsertRankingsSiteServices = async (rankingId, body) => {
  const headers = await getAuthorizationHeader()

  return API.post('datsururin', `v1/rankings/${rankingId}/site-services`, {
    body,
    headers,
    response: true
  })
}

export {
  createRanking,
  readRanking,
  readRankings,
  updateRanking,
  readRankingsSiteServices,
  batchUpsertRankingsSiteServices,
}
