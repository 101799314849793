import Form from 'react-bootstrap/Form'

const EnumField = ({disabled, options, name, onChange, value}) => {
  return (
    <Form.Select disabled={disabled === true} name={name} onChange={onChange} value={value}>
      <option value="">選択</option>
      {options.map(item => 
        <option key={item} value={item}>{item}</option>
      )}
    </Form.Select>
  )
}

export default EnumField
