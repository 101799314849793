import {Auth} from 'aws-amplify'

export const DEFAULT_LIMIT = 30

export const getAuthorizationHeader = async () => {
  const {idToken: {jwtToken}} = await Auth.currentSession()
  return {Authorization: jwtToken}
}

export const getVersion = () => {
  return Date.now().toString()
}

export const parseErrors = errors => {
  if(typeof errors == 'object') {
    if(errors.response) {
      // API
      if(errors.response.data) {
        // credit-cards API
        if(errors.response.data.errors) {
          return errors.response.data.errors
        }
      }
    }
  }

  return [{message: errors.toString()}]
}
