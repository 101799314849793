export const DATA_TYPE_PAGE = 'page'
export const DATA_TYPE_SERVICE = 'service'
export const DATA_TYPE_SITE_SERVICE = 'site_service'

export const DATA_TYPES_JA_MAP = {
    [DATA_TYPE_PAGE]: 'ページ',
    [DATA_TYPE_SERVICE]: '共通サービス',
    [DATA_TYPE_SITE_SERVICE]: 'サイト別サービス',
}

export const UI_TYPE_TEXTAREA = 'textarea'
export const UI_TYPE_MARKUP = 'markup'
export const UI_TYPE_HTML = 'html'
export const UI_TYPE_IMAGE = 'image'
export const UI_TYPE_RANKING = 'ranking'
export const UI_TYPE_SITE_SERVICE = 'site_service'
export const UI_TYPE_SWITCH = 'switch'
// export const UI_TYPE_FILE = 6
// export const UI_TYPE_RANK = 7

export const UI_TYPES_JA = {
    [UI_TYPE_TEXTAREA]: 'テキストエリア',
    [UI_TYPE_MARKUP]: 'マークアップ',
    [UI_TYPE_HTML]: 'HTML',
    [UI_TYPE_IMAGE]: '画像',
    [UI_TYPE_RANKING]: 'ランキング',
    [UI_TYPE_SITE_SERVICE]: 'サイト別サービス',
    [UI_TYPE_SWITCH]: 'スイッチ',
    // '6': 'ファイル',
}
