import React, {useEffect, useState} from 'react'
import Collapse from 'react-bootstrap/Collapse'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Stack from 'react-bootstrap/Stack'
import ErrorMessage from './ErrorMessage'
import JsonSchemaSelect from './JsonSchemaSelect'
import JsonSchemaDataForm from './JsonSchemaDataForm'
import {parseErrors} from '../../api'
import {createServicesJsonSchema, updateServicesJsonSchema} from '../../api/services'
import {createPagesJsonSchema, updatePagesJsonSchema} from '../../api/pages'
import {createSiteServicesJsonSchema, updateSiteServicesJsonSchema} from '../../api/site-services'
import {
  DATA_TYPE_SERVICE,
  DATA_TYPE_PAGE,
  DATA_TYPE_SITE_SERVICE
} from '../../constants/json-schemas'

const STATUS_UNCHANGED = 'unchanged'
const STATUS_CHANGED = 'changed'
const STATUS_SAVING = 'saving'
const STATUS_SAVED = 'saved'
const BUTTON_TEXT = {
  [STATUS_UNCHANGED]: '変更なし',
  [STATUS_CHANGED]: '保存する',
  [STATUS_SAVING]: '保存中…',
  [STATUS_SAVED]: '保存完了',
}

const JsonSchemaDataListFormItem = ({dataType, onClickRemove, siteId, values: initialValues}) => {
  const [errors, setErrors] = useState()
  const [formValues, setFormValues] = useState({})
  const [status, setStatus] = useState(STATUS_UNCHANGED)
  const [open, setOpen] = useState(true)

  const handleChange = e => {
    const {target: {name, value}} = e

    setStatus(STATUS_CHANGED)

    setFormValues({...formValues, [name]: value})

    if(name === 'json_schema_id' && !open) {
      setOpen(true)
    }
  }

  const handleSubmit = async e => {
    setStatus(STATUS_SAVING)

    try {
      if(dataType === DATA_TYPE_SERVICE) {
        if(formValues.id) {
          await updateServicesJsonSchema(formValues.service_id, formValues, formValues.id)
        }
        else {
          await createServicesJsonSchema(formValues.service_id, formValues)
        }
      }
      else if(dataType === DATA_TYPE_PAGE) {
        if(formValues.id) {
          await updatePagesJsonSchema(formValues.page_id, formValues, formValues.id)
        }
        else {
          await createPagesJsonSchema(formValues.page_id, formValues)
        }
      }
      else if(dataType === DATA_TYPE_SITE_SERVICE) {
        if(formValues.id) {
          await updateSiteServicesJsonSchema(formValues.site_service_id, formValues, formValues.id)
        }
        else {
          await createSiteServicesJsonSchema(formValues.site_service_id, formValues)
        }
      }
      setStatus(STATUS_SAVED)
    }
    catch(err) {
      setStatus(STATUS_CHANGED)
      setErrors(parseErrors(err))
    }
  }

  useEffect(() => {
    setFormValues(initialValues)
  }, [initialValues])

  return (
    <div>
      <ErrorMessage errors={errors}/>
      <Card bg={status === STATUS_CHANGED ? 'light' : ''}>
        <Card.Header>
          <Stack direction="horizontal">
            <div>JSON Schema データ{status === STATUS_CHANGED ? '（未保存）' : ''}</div>
            {formValues.json_schema_id &&
              <div className="ms-auto">
                <Button
                  onClick={() => setOpen(!open)}
                  size="sm"
                  type="button"
                  variant=""
                >
                  {open ? '閉じる' : '開く'}
                </Button>
              </div>
            }
          </Stack>
        </Card.Header>
        <Card.Body>
          <Form.Group className="mb-3">
            <JsonSchemaSelect
              dataType={dataType}
              onChange={handleChange}
              value={formValues.json_schema_id}
            />
          </Form.Group>
          <Collapse in={open}>
            <div>
              {formValues.json_schema_id &&
                <div>
                  <Form.Group className="mb-3">
                    <JsonSchemaDataForm
                      errors={errors}
                      isCard={false}
                      jsonSchemaId={formValues.json_schema_id}
                      onChange={v => handleChange({target: {name: 'json_schema_data', 'value': v}})}
                      siteId={siteId}
                      values={formValues.json_schema_data}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="d-grid gap-2">
                      <Button
                        disabled={status === STATUS_UNCHANGED}
                        onClick={handleSubmit}
                        size="sm"
                        type="button"
                        variant="outline-primary"
                      >
                        {BUTTON_TEXT[status]}
                      </Button>
                    </div>
                  </Form.Group>
                </div>
              }
            </div>
          </Collapse>
        </Card.Body>
      </Card>
    </div>
  )
}

export default JsonSchemaDataListFormItem
