import {useEffect, useState}  from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {useNavigate, useParams} from 'react-router-dom'
import ErrorMessage from './ui/ErrorMessage'
import FormContainer from './ui/FormContainer'
import Headline from './ui/Headline'
import ImageField from './ui/ImageField'
import JsonSchemaDataListForm from './ui/JsonSchemaDataListForm'
import {parseErrors} from '../api'
import {readServices} from '../api/services'
import {createMachine, readMachine, updateMachine} from '../api/machines'

const INITIAL_FORM_VALUES = {
  service_id: '',
  name: '',
  machine_type: '',
  pain: '',
  features: '',
  image_url: '',
}

const MachineForm = () => {
  const [services, setServices] = useState([])
  const [errors, setErrors] = useState()
  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES)
  const [isReady, setIsReady] = useState(false)
  const [sites, setSites] = useState([])

  let navigate = useNavigate()
  const {id} = useParams()

  const handleChange = e => {
    const {target: {name, value}} = e
    setFormValues({...formValues, [name]: value})
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      if(id) {
        await updateMachine(id, formValues)
      }
      else {
        await createMachine(formValues)
      }
      navigate('/machines', {replace: true})
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  const initForm = async id => {
    try {
      const newFormValues = !id ? INITIAL_FORM_VALUES : await readMachine(id)
      setFormValues(newFormValues)
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
    finally {
      setIsReady(true)
    }
  }

  const initRelations = async () => {
    try {
      const r = await readServices({
        fields: ['id', 'name'],
        limit: 9999,
        page: 1,
      })

      setServices(r.data)
    }
    catch(err) {
      console.log(err)
      setErrors(parseErrors(err))
    }
  }

  useEffect(() => {
    initForm(id)
    initRelations()
  }, [id])

  return (
    <div>
      <Headline>脱毛機{id ? '編集' : '追加'}</Headline>
      {isReady &&
        <FormContainer>
          <ErrorMessage errors={errors}/>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>共通サービス</Form.Label>
              <Form.Select name="service_id" onChange={handleChange} value={formValues.service_id}>
                <option value="">選択</option>
                {services.map(item => 
                  <option key={item.id} value={item.id}>{item.name}</option>
                )}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>機械名</Form.Label>
              <Form.Control
                name="name"
                onChange={handleChange}
                placeholder="破壊王"
                type="text"
                value={formValues.name}
              />
              <ErrorMessage errors={errors} errorKey="name"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>タイプ</Form.Label>
              <Form.Control
                name="machine_type"
                onChange={handleChange}
                placeholder="熱破壊式"
                type="text"
                value={formValues.machine_type}
              />
              <ErrorMessage errors={errors} errorKey="machine_type"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>痛み</Form.Label>
              <Form.Control
                name="pain"
                onChange={handleChange}
                placeholder="やや強い"
                type="text"
                value={formValues.pain}
              />
              <ErrorMessage errors={errors} errorKey="pain"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>特徴</Form.Label>
              <Form.Control
                name="features"
                onChange={handleChange}
                placeholder="太い毛に有効"
                type="text"
                value={formValues.features}
              />
              <ErrorMessage errors={errors} errorKey="features"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>画像</Form.Label>
              <ImageField
                name="image_url"
                onChange={handleChange}
                value={formValues.image_url}
              />
              <ErrorMessage errors={errors} errorKey="image_url"/>
            </Form.Group>
            <div className="d-grid gap-2">
              <Button type="submit" variant="primary">保存</Button>
            </div>
          </Form>
        </FormContainer>
      }
    </div>
  )
}

export default MachineForm
