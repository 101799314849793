const settings = {
  API: {
    endpoints: [
      {
        name: 'datsururin',
        endpoint: process.env.REACT_APP_API_ENDPOINT_DATSURURIN,
      },
      {
        name: 'local',
        endpoint: process.env.REACT_APP_API_ENDPOINT_LOCAL,
      },
    ],
  },
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION, 
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID_EREVISTA,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID_EREVISTA, 
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BUCKET, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_AWS_REGION, 
    }
  }
}

export default settings
