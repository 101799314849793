import {useEffect, useState}  from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {useNavigate, useParams} from 'react-router-dom'
import ErrorMessage from './ui/ErrorMessage'
import FormContainer from './ui/FormContainer'
import Headline from './ui/Headline'
import JsonField from './ui/JsonField'
import {parseErrors} from '../api'
import {createJsonSchema, readJsonSchema, updateJsonSchema} from '../api/json-schemas'
import {DATA_TYPES_JA_MAP} from '../constants/json-schemas'

const INITIAL_FORM_VALUES = {
  name: '',
  data_type: '0',
  json_schema: ''
}

const JsonSchemaForm = () => {
  const [errors, setErrors] = useState()
  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES)
  const [isReady, setIsReady] = useState(false)

  let navigate = useNavigate()
  const {id} = useParams()

  const handleChange = e => {
    const {target: {name, value}} = e
    setFormValues({...formValues, [name]: value})
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      if(id) {
        await updateJsonSchema(id, formValues)
      }
      else {
        await createJsonSchema(formValues)
      }
      navigate('/json-schemas', {replace: true})
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  const initForm = async id => {
    try {
      const newFormValues = !id ? INITIAL_FORM_VALUES : await readJsonSchema(id)
      setFormValues(newFormValues)
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
    finally {
      setIsReady(true)
    }
  }

  useEffect(() => {
    initForm(id)
  }, [id])

  return (
    <div>
      <Headline>JSON Schema {id ? '編集' : '追加'}</Headline>
      {isReady &&
        <FormContainer>
          <ErrorMessage errors={errors}/>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>名前</Form.Label>
              <Form.Control
                name="name"
                onChange={handleChange}
                placeholder="共通クレカ"
                type="text"
                value={formValues.name}
              />
              <ErrorMessage errors={errors} errorKey="name"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>タイプ</Form.Label>
              <Form.Select
                name="data_type"
                onChange={handleChange}
                value={formValues.data_type}
              >
                <option>選択</option>
                {Object.keys(DATA_TYPES_JA_MAP).map(k =>
                  <option key={k} value={k}>{DATA_TYPES_JA_MAP[k]}</option>
                )}
              </Form.Select>
              <ErrorMessage errors={errors} errorKey="data_type"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <JsonField
                label="JSON Schema"
                name="json_schema"
                onChange={handleChange}
                value={formValues.json_schema}
              />
              <ErrorMessage errors={errors} errorKey="json_schema"/>
            </Form.Group>
            <div className="d-grid gap-2">
              <Button type="submit" variant="primary">保存</Button>
            </div>
          </Form>
        </FormContainer>
      }
    </div>
  )
}

export default JsonSchemaForm
