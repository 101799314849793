import {useEffect, useState} from 'react'
import Form from 'react-bootstrap/Form'
import {readRankings} from '../../api/rankings'

const RankingField = ({disabled, name = 'ranking_id', onChange, siteId, value}) => {
  const [rankings, setRankings] = useState([])

  const handleChange = e => {
    const {target: {name, value}} = e
    onChange({target: {name, value: Number(value)}})
  }

  const init = async siteId => {
    const r = await readRankings({page: 1, limit: -1, siteId})
    setRankings(r.data)
  }

  useEffect(() => {
    if(!siteId) {
      return
    }
    init(siteId)
  }, [siteId])

  return (
    <Form.Select disabled={disabled === true} name={name} onChange={handleChange} value={value}>
      <option value="">選択</option>
      {rankings.map(item => 
        <option key={item.id} value={item.id}>{item.name}</option>
      )}
    </Form.Select>
  )
}

export default RankingField
