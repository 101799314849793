import {useEffect, useState} from 'react'
import Form from 'react-bootstrap/Form'
import {readSiteServices} from '../../api/site-services'

const SiteServiceField = ({disabled, name = 'site_service_id', onChange, siteId, value}) => {
  const [siteServices, setSiteServices] = useState([])

  const handleChange = e => {
    const {target: {name, value}} = e
    onChange({target: {name, value: Number(value)}})
  }

  const init = async siteId => {
    const r = await readSiteServices({includeJsonSchemas: false, page: 1, limit: -1, siteId})
    setSiteServices(r.data)
  }

  useEffect(() => {
    if(!siteId) {
      return
    }
    init(siteId)
  }, [siteId])

  return (
    <Form.Select disabled={disabled === true} name={name} onChange={handleChange} value={value}>
      <option value="">選択</option>
      {siteServices.map(item => 
        <option key={item.id} value={item.id}>{item.service.name}</option>
      )}
    </Form.Select>
  )
}

export default SiteServiceField
