import {useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Stack from 'react-bootstrap/Stack'
import Table from 'react-bootstrap/Table'
import {Link, useSearchParams} from 'react-router-dom'
import Datetime from './ui/Datetime'
import ErrorMessage from './ui/ErrorMessage'
import Headline from './ui/Headline'
import InvalidationForm from './ui/InvalidationForm'
import Pagination from './ui/Pagination'
import {parseErrors} from '../api'
import {readShops} from '../api/shops'
import {readServices} from '../api/services'

const ShopList = ({user}) => {
  const [errors, setErrors] = useState([])
  const [formValues, setFormValues] = useState({is_available: 'true'})
  const [page, setPage] = useState()
  const [pagination, setPagination] = useState({})
  const [servicesMap, setServicesMap] = useState({})
  
  const [searchParams, setSearchParams] = useSearchParams()

  const handleChange = e => {
    const {target: {name, value}} = e
    setFormValues({formValues, [name]: value})
  }

  const handleClick = e => {
    setSearchParams({is_available: formValues.is_available, page: 1})
  }

  const initRelations = async reviews => {
    let serviceIds = []
    for(let review of reviews) {
      if(!serviceIds.includes(review.service_id)) {
        serviceIds.push(review.service_id)
      }
    }

    const r = await readServices({
      ids: serviceIds,
      includeJsonSchemas: false,
      includeMachines: false,
      includeReviews: false,
      limit: -1,
      page: 1
    })

    let newServicesMap = {}
    for(let d of r.data) {
      newServicesMap[d.id.toString()] = d
    }

    setServicesMap(newServicesMap)
  }

  const paginate = async () => {
    const r = await readShops({
      fields: ['id', 'name', 'service_id', 'created_at', 'updated_at'],
      isAvailable: searchParams.get('is_available') == 'false' ? 'false' : 'true',
      page: searchParams.get('page') || 1
    })
    try {
      setPagination(r)
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  useEffect(() => {
    if(pagination.data) {
      initRelations(pagination.data)
    }
  }, [pagination])

  useEffect(() => {
    setFormValues({
      ...formValues,
      is_available: searchParams.get('is_available') == 'false' ? 'false' : 'true'
    })

    paginate()
  }, [searchParams])

  return (
    <div>
      <Headline>店舗一覧</Headline>
      <InvalidationForm/>
      <ErrorMessage errors={errors}/>
      <Form className="d-flex mb-4">
        <Form.Group className="me-2">
          <Form.Select name="is_available" onChange={handleChange} value={formValues.is_available}>
            <option value="true">表示</option>
            <option value="false">非表示</option>
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Button onClick={handleClick} variant="primary">検索</Button>
        </Form.Group>
      </Form>
      {pagination.data &&
        <Table striped bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>名前</th>
              <th>サービス</th>
              <th>作成日時</th>
              <th>更新日時</th>
              <th>アクション</th>
            </tr>
          </thead>
          <tbody>
            {pagination.data.map(item => {
              let service = servicesMap[item.service_id.toString()]
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{service ? service.name : ''}</td>
                  <td><Datetime date={item.created_at}/></td>
                  <td><Datetime date={item.updated_at}/></td>
                  <td>
                    <Link to={`/shops/${item.id}`}>編集</Link>
                    <Link className="ms-3" to={`/shops/${item.id}/accesses`}>店舗アクセス編集</Link>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      }
      <Pagination page={page} pages={pagination.pages}/>
    </div>
  )
}

export default ShopList
