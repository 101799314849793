import React, {useState} from 'react'
import Button from 'react-bootstrap/Button'
import JsonSchemaDataListFormItem from './JsonSchemaDataListFormItem'
import {DATA_TYPE_PAGE, DATA_TYPE_SERVICE, DATA_TYPE_SITE_SERVICE} from '../../constants/json-schemas'

const initialValues = {
  json_schema_id: '',
  json_schema_data: '',
}

const getInitialValues = (dataType, id) => {
  let k
  if(dataType === DATA_TYPE_PAGE) {
    k = 'page_id'
  }
  else if(dataType === DATA_TYPE_SERVICE) {
    k = 'service_id'
  }
  else if(dataType === DATA_TYPE_SITE_SERVICE) {
    k = 'site_service_id'
  }

  if(!k) {
    return initialValues
  }

  return {...initialValues, [k]: id}
}

const JsonSchemaDataListForm = ({dataType, id, siteId, values}) => {
  const [formValuesList, setFormValuesList] = useState(values || [getInitialValues(dataType, id)])

  const handleClickAdd = e => {
    setFormValuesList(formValuesList.concat([getInitialValues(dataType, id)]))
  }

  return (
    <div className="d-grid gap-2 mb-4">
      <Button className="mb-3" onClick={handleClickAdd} variant="outline-info">
        JSON Schema を追加
      </Button>
      {formValuesList.map((item, i) => {
        return <JsonSchemaDataListFormItem
          dataType={dataType}
          key={i}
          siteId={siteId}
          values={item}
        />
      })}
    </div>
  )
}

export default JsonSchemaDataListForm
