import BsPagination from 'react-bootstrap/Pagination'
import {useSearchParams} from 'react-router-dom'

const Pagination = ({page, pages}) => {
  let [searchParams, setSearchParams] = useSearchParams()

  const handleClick = page => e => {
    searchParams.set('page', page)
    setSearchParams(searchParams)
  }

  if(!pages || pages < 2) {
    return null
  }

  return (
    <BsPagination>
      {[...Array(pages).keys()].map(i => {
        const idx = i + 1
        return (
          <BsPagination.Item
            active={idx === page}
            key={idx}
            onClick={handleClick(idx)}
          >
            {idx}
          </BsPagination.Item>
        )
      })}
    </BsPagination>
  )
}

export default Pagination
