import {API} from 'aws-amplify'
import {DEFAULT_LIMIT, getAuthorizationHeader, getVersion} from '../'

const createMachine = async body => {
  const headers = await getAuthorizationHeader()

  return API.post('datsururin', `v1/machines`, {
    body,
    headers,
    response: true
  })
}

const readMachine = async id => {
  const headers = await getAuthorizationHeader()
  
  return API.get('datsururin', `v1/machines/${id}?v=${getVersion()}`, {
    headers
  })
}

const readMachines = async ({fields, page, limit = DEFAULT_LIMIT}) => {
  const headers = await getAuthorizationHeader()

  let queryStringParameters = {
    order: 'DESC',
    orderby: 'id',
    limit,
    page,
    v: getVersion(),
  }
  if(fields) {
    queryStringParameters.fields = fields
  }

  return API.get('datsururin', 'v1/machines', {
    headers, queryStringParameters
  })
}

const updateMachine = async (id, body) => {
  const headers = await getAuthorizationHeader()

  return API.put('datsururin', `v1/machines/${id}`, {
    body,
    headers,
    response: true
  })
}

export {
  createMachine,
  readMachine,
  readMachines,
  updateMachine,
}
