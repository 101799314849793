import Form from 'react-bootstrap/Form'

const SwitchField = ({checked, label, name, onChange}) => {
  return (
    <Form.Check 
      checked={checked}
      label={label}
      name={name}
      onChange={onChange}
      type="switch"
    />
  )
}

export default SwitchField
