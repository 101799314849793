import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import {LinkContainer} from 'react-router-bootstrap'

const NavbarComponent = ({onClickSignOut}) => {

  const handleClickSignOut = e => {
    e.preventDefault()

    onClickSignOut()
  }

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>だつるりん管理画面</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="JSON Schema">
              <LinkContainer to="json-schemas">
                <NavDropdown.Item>一覧</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="json-schemas/create">
                <NavDropdown.Item>追加</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <NavDropdown title="共通サービス">
              <LinkContainer to="services">
                <NavDropdown.Item>一覧</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="services/create">
                <NavDropdown.Item>追加</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <NavDropdown title="サイト別サービス">
              <LinkContainer to="site-services">
                <NavDropdown.Item>一覧</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="site-services/create">
                <NavDropdown.Item>追加</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <NavDropdown title="ランキング">
              <LinkContainer to="rankings">
                <NavDropdown.Item>一覧</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="rankings/create">
                <NavDropdown.Item>追加</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <NavDropdown title="ページ">
              <LinkContainer to="pages">
                <NavDropdown.Item>一覧</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="pages/create">
                <NavDropdown.Item>追加</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <NavDropdown title="口コミ">
              <LinkContainer to="reviews">
                <NavDropdown.Item>一覧</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="reviews/create">
                <NavDropdown.Item>追加</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <NavDropdown title="脱毛機">
              <LinkContainer to="machines">
                <NavDropdown.Item>一覧</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="machines/create">
                <NavDropdown.Item>追加</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <NavDropdown title="店舗">
              <LinkContainer to="shops">
                <NavDropdown.Item>一覧</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="shops/create">
                <NavDropdown.Item>追加</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <NavDropdown title="CF">
              <LinkContainer to="cloud-front-distributions">
                <NavDropdown.Item>一覧</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="cloud-front-distributions/create">
                <NavDropdown.Item>追加</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
          </Nav>
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Link href="#home" onClick={handleClickSignOut}>ログアウト</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavbarComponent
