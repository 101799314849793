import {API} from 'aws-amplify'
import {DEFAULT_LIMIT, getAuthorizationHeader, getVersion} from '../'

const createSiteService = async body => {
  const headers = await getAuthorizationHeader()

  return API.post('datsururin', `v1/site-services`, {
    body,
    headers,
    response: true
  })
}

const readSiteService = async id => {
  const headers = await getAuthorizationHeader()
  
  return API.get('datsururin', `v1/site-services/${id}?v=${getVersion()}`, {
    headers
  })
}

const readSiteServices = async ({fields, page, limit = DEFAULT_LIMIT, siteId = null}) => {
  const headers = await getAuthorizationHeader()

  let queryStringParameters = {
    order: 'DESC',
    orderby: 'id',
    limit,
    page,
    v: getVersion(),
  }
  if(fields) {
    queryStringParameters.fields = fields
  }
  if(siteId) {
    queryStringParameters.site_id = siteId
  }

  return API.get('datsururin', 'v1/site-services', {
    headers, queryStringParameters
  })
}

const updateSiteService = async (id, body) => {
  const headers = await getAuthorizationHeader()

  return API.put('datsururin', `v1/site-services/${id}`, {
    body,
    headers,
    response: true
  })
}

const createSiteServicesJsonSchema = async (id, body) => {
  const headers = await getAuthorizationHeader()

  return API.post('datsururin', `v1/site-services/${id}/json-schemas`, {
    body,
    headers,
    response: true
  })
}

const updateSiteServicesJsonSchema = async (id, body, siteServicesJsonSchemaId) => {
  const headers = await getAuthorizationHeader()

  return API.put('datsururin', `v1/site-services/${id}/json-schemas/${siteServicesJsonSchemaId}`, {
    body,
    headers,
    response: true
  })
}

export {
  createSiteService,
  readSiteService,
  readSiteServices,
  updateSiteService,
  createSiteServicesJsonSchema,
  updateSiteServicesJsonSchema,
}
