import {useEffect, useState}  from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {useNavigate, useParams} from 'react-router-dom'
import ErrorMessage from './ui/ErrorMessage'
import FormContainer from './ui/FormContainer'
import Headline from './ui/Headline'
import JsonSchemaDataListForm from './ui/JsonSchemaDataListForm'
import {parseErrors} from '../api'
import {
  createCloudFrontDistribution,
  invalidateCloudFrontDistribution,
  readCloudFrontDistribution,
  updateCloudFrontDistribution
} from '../api/cloud-front-distributions'

const INITIAL_FORM_VALUES = {
  cloud_front_distribution_id: '',
  name: '',
}

const CloudFrontDistributionForm = () => {
  const [errors, setErrors] = useState()
  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES)
  const [isReady, setIsReady] = useState(false)

  let navigate = useNavigate()
  const {id} = useParams()

  const handleChange = e => {
    const {target: {checked, name, value}} = e

    setFormValues({...formValues, [name]: value})
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      if(id) {
        await updateCloudFrontDistribution(id, formValues)
      }
      else {
        await createCloudFrontDistribution(formValues)
      }
      navigate('/cloud-front-distributions', {replace: true})
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  const initForm = async id => {
    try {
      let newFormValues
      if(!id) {
        newFormValues = INITIAL_FORM_VALUES
      }
      else {
        const r = await readCloudFrontDistribution(id)
        newFormValues = r 
      }
      setFormValues(newFormValues)
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
    finally {
      setIsReady(true)
    }
  }

  useEffect(() => {
    initForm(id)
  }, [id])

  return (
    <div>
      <Headline>CloudFront ディストリビューション{id ? '編集' : '追加'}</Headline>
      {isReady &&
        <FormContainer>
          <ErrorMessage errors={errors}/>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>CloudFront ディストリビューション ID</Form.Label>
              <Form.Control
                name="cloud_front_distribution_id"
                onChange={handleChange}
                placeholder="ABCD1234"
                type="text"
                value={formValues.cloud_front_distribution_id}
              />
              <ErrorMessage errors={errors} errorKey="name"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>名前</Form.Label>
              <Form.Control
                name="name"
                onChange={handleChange}
                placeholder="だつるりん（脱毛ニュース）"
                type="text"
                value={formValues.name}
              />
              <ErrorMessage errors={errors} errorKey="name"/>
            </Form.Group>
            <div className="d-grid gap-2">
              <Button type="submit" variant="primary">CloudFront ディストリビューション保存</Button>
            </div>
          </Form>
        </FormContainer>
      }
    </div>
  )
}

export default CloudFrontDistributionForm
