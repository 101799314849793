import {useEffect, useState} from 'react'
import Table from 'react-bootstrap/Table'
import {Link, useSearchParams} from 'react-router-dom'
import Datetime from './ui/Datetime'
import ErrorMessage from './ui/ErrorMessage'
import Headline from './ui/Headline'
import InvalidationForm from './ui/InvalidationForm'
import Pagination from './ui/Pagination'
import {parseErrors} from '../api'
import {readRankings} from '../api/rankings'
import {readSites} from '../api/sites'

const RankingList = ({user}) => {
  const [errors, setErrors] = useState([])
  const [page, setPage] = useState()
  const [pagination, setPagination] = useState({})
  const [sitesMap, setSitesMap] = useState({})
  
  const searchParams = useSearchParams()[0]

  const init = async () => {
    const r = await readSites(1, 9999)
    
    try {
      let sm = {}
      for(let d of r.data) {
        sm[d.id.toString()] = d
      }
      setSitesMap(sm)
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  const paginate = async page => {
    const r = await readRankings({fields: ['id', 'site_id', 'name', 'created_at', 'updated_at'], page: page || 1})
    try {
      setPagination(r)
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    let newPage = Number(searchParams.get('page'))
    if(isNaN(newPage) || newPage === 0) {
      newPage = 1
    }
    setPage(newPage)

    paginate(newPage)
  }, [searchParams])

  return (
    <div>
      <Headline>ランキング一覧</Headline>
      <InvalidationForm/>
      <ErrorMessage errors={errors}/>
      {pagination.data &&
        <Table striped bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>名前</th>
              <th>サイト</th>
              <th>作成日時</th>
              <th>更新日時</th>
              <th>アクション</th>
            </tr>
          </thead>
          <tbody>
            {pagination.data.map(item => {
              let site = sitesMap[item.site_id.toString()]
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{site ? site.name : ''}</td>
                  <td><Datetime date={item.created_at}/></td>
                  <td><Datetime date={item.updated_at}/></td>
                  <td>
                    <Link to={`/rankings/${item.id}`}>編集</Link>
                    <Link className="ms-3" to={`/rankings/${item.id}/site-services`}>ランキング内容編集</Link>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      }
      <Pagination page={page} pages={pagination.pages}/>
    </div>
  )
}

export default RankingList
