import React, {Fragment, useEffect, useState} from 'react'
import Card from 'react-bootstrap/Card'
import Form, {Select} from 'react-bootstrap/Form'
import EnumField from './EnumField'
import ErrorMessage from './ErrorMessage'
import HtmlField from './HtmlField'
import ImageField from './ImageField'
import RankingField from '../ui/RankingField'
import SiteServiceField from '../ui/SiteServiceField'
import SwitchField from '../ui/SwitchField'
import {parseErrors} from '../../api'
import {readJsonSchema, readJsonSchemaUis} from '../../api/json-schemas'
import {
  UI_TYPE_HTML, UI_TYPE_IMAGE, UI_TYPE_RANKING,
  UI_TYPE_TEXTAREA, UI_TYPE_SITE_SERVICE, UI_TYPE_SWITCH
} from '../../constants/json-schemas'


const JsonSchemaDataFormWithoutCard = ({disabled, errors, formValues, jsdErrors, jsonSchema, jsonSchemaUisMap, onChange, siteId}) => {
  return (
    <Fragment>
      <ErrorMessage errors={jsdErrors} errorKey="json_schema_data"/>
      <ErrorMessage errors={errors}/>
      {jsonSchema &&
        <div className="mt-3">
          {Object.keys(jsonSchema.properties).map(k => {
            const property = jsonSchema.properties[k]
            
            let ui = property.format || property.type

            const customUi = jsonSchemaUisMap[k] || {}
            if(customUi.hasOwnProperty('ui_type')) {
              ui = customUi.ui_type
            }

            let Cmp = Form.Control
            let cmpProps = {
              disabled: disabled === true,
              name: k,
              onChange: onChange(ui),
              type: 'text',
              value: formValues[k] || '',
            }

            if(property.enum) {
              Cmp = EnumField
              cmpProps.options = property.enum
            }
            else if(ui === 'number') {
              cmpProps.type = 'number'
            }
            else if(ui === 'uri') {
              cmpProps.type = 'url'
            } 
            else if(ui === UI_TYPE_HTML) {
              Cmp = HtmlField
            }
            else if(ui === UI_TYPE_IMAGE) {
              Cmp = ImageField
            } 
            else if(ui === UI_TYPE_RANKING) {
              Cmp = RankingField
              cmpProps.siteId = siteId
            } 
            else if(ui === UI_TYPE_SITE_SERVICE) {
              Cmp = SiteServiceField
              cmpProps.siteId = siteId
            } 
            else if(ui === UI_TYPE_SWITCH) {
              Cmp = SwitchField
              cmpProps.checked = formValues[k] === true
            }
            else if(ui === UI_TYPE_TEXTAREA) {
              delete cmpProps.type

              cmpProps.as = 'textarea'
              cmpProps.rows = 3
            }

            return (
              <Form.Group className="mb-3" key={k}>
                <Form.Label>{property.description}</Form.Label>
                <Cmp {...cmpProps}/>
              </Form.Group>
            )
          })}
        </div>
      }
    </Fragment>
  )
}


const JsonSchemaDataForm = ({disabled, errors: jsdErrors, isCard, jsonSchemaId, onChange, properties, siteId, uisMap, values: initialValues}) => {
  const [errors, setErrors] = useState()
  const [jsonSchema, setJsonSchema] = useState()
  const [jsonSchemaUisMap, setJsonSchemaUisMap] = useState({})
  const [formValues, setFormValues] = useState(initialValues || {})

  const handleChange = ui => e => {
    const {target: {checked, name, type, value}} = e

    let v = ui == UI_TYPE_SWITCH ? checked : value
    if(type === 'number') {
      v = Number(v)
    }
    const newValues = {...formValues, [name]: v}
    
    setFormValues(newValues)
    onChange(newValues)
  }

  const updateJsonSchema = async jsonSchemaId => {
    try {
      const rs = await Promise.all([
        readJsonSchema(jsonSchemaId),
        readJsonSchemaUis(jsonSchemaId)
      ])

      let newJsonSchemaUisMap = {}
      for(let item of rs[1].data) {
        newJsonSchemaUisMap[item.json_schema_key] = item
      }
      setJsonSchemaUisMap(newJsonSchemaUisMap)

      setJsonSchema(JSON.parse(rs[0].json_schema))
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  useEffect(() => {
    if(!jsonSchemaId) {
      return
    }

    updateJsonSchema(jsonSchemaId)
  }, [jsonSchemaId])


  if(isCard === false) {
    return (
      <JsonSchemaDataFormWithoutCard
        disabled={disabled}
        errors={errors}
        formValues={formValues}
        jsdErrors={jsdErrors}
        jsonSchema={jsonSchema}
        jsonSchemaUisMap={jsonSchemaUisMap}
        onChange={handleChange}
        siteId={siteId}
      />
    )
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title>JSON Schema データ</Card.Title>
        <JsonSchemaDataFormWithoutCard
          disabled={disabled}
          errors={errors}
          formValues={formValues}
          jsdErrors={jsdErrors}
          jsonSchema={jsonSchema}
          jsonSchemaUisMap={jsonSchemaUisMap}
          onChange={handleChange}
          siteId={siteId}
        />     
      </Card.Body>
    </Card>
  )
}

export default JsonSchemaDataForm
