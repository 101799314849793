import Container from 'react-bootstrap/Container'
import {Outlet} from 'react-router-dom'
import Navbar from './ui/Navbar'

const AdminApp = ({signOut, user}) => {
  return (
    <div>
      <Navbar onClickSignOut={signOut}/>
      <Container className="mt-4">
        <Outlet/>
      </Container>
    </div>
  )
}

export default AdminApp
