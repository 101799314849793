import {API} from 'aws-amplify'
import {DEFAULT_LIMIT, getAuthorizationHeader, getVersion} from '../'

const createReview = async body => {
  const headers = await getAuthorizationHeader()

  return API.post('datsururin', `v1/reviews`, {
    body,
    headers,
    response: true
  })
}

const readReview = async id => {
  const headers = await getAuthorizationHeader()
  
  return API.get('datsururin', `v1/reviews/${id}?v=${getVersion()}`, {
    headers
  })
}

const readReviews = async ({fields, page, limit = DEFAULT_LIMIT}) => {
  const headers = await getAuthorizationHeader()

  let queryStringParameters = {
    order: 'DESC',
    orderby: 'id',
    limit,
    page,
    v: getVersion(),
  }
  if(fields) {
    queryStringParameters.fields = fields
  }

  return API.get('datsururin', 'v1/reviews', {
    headers, queryStringParameters
  })
}

const updateReview = async (id, body) => {
  const headers = await getAuthorizationHeader()

  return API.put('datsururin', `v1/reviews/${id}`, {
    body,
    headers,
    response: true
  })
}

export {
  createReview,
  readReview,
  readReviews,
  updateReview,
}
