import {useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import {useNavigate, useParams} from 'react-router-dom'
import FormContainer from './ui/FormContainer'
import Headline from './ui/Headline'
import ErrorMessage from './ui/ErrorMessage'
import {parseErrors} from '../api'
import {batchUpsertRankingsSiteServices, readRanking, readRankingsSiteServices} from '../api/rankings'
import {readSiteServices} from '../api/site-services'

const INITIAL_FORM_VALUES = {
  site_service_id: '',
  priority: '',
}

const RankingSiteServiceForm = () => {
  const [errors, setErrors] = useState()
  const [formValues, setFormValues] = useState()
  const [siteServices, setSiteServices] = useState()

  let navigate = useNavigate()
  const {id} = useParams()

  const handleChange = index => e => {
    const {target: {name, value}} = e

    const newFormValues = {...formValues[index], [name]: value}
    setFormValues(formValues.map((item, i) => i === index ? newFormValues : item))
  }

  const handleClickAdd = e => {
    setFormValues(formValues.concat(INITIAL_FORM_VALUES))
  }

  const handleClickRemove = index => e => {
    setFormValues(formValues.filter((item, i) => i !== index))
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      await batchUpsertRankingsSiteServices(id, formValues.map(item => ({...item, ranking_id: id})))
      navigate('/rankings', {replace: true})
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  const initForm = async id => {
    try {
      const rs = await Promise.all([
        readRankingsSiteServices(id),
        readRanking(id),
      ])

      const r = await readSiteServices({fields: ['id', 'site_id', '-service.shops', 'service.name'], page: 1, limit: -1, siteId: rs[1].site_id})
      setSiteServices(r.data)

      setFormValues(rs[0])
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  useEffect(() => {
    initForm(id)
  }, [id])

  return (
    <div>
      <Headline>ランキング内容{id ? '編集' : '追加'}</Headline>
      <ErrorMessage errors={errors}/>
      {Array.isArray(formValues) &&
        <FormContainer>
          <Form onSubmit={handleSubmit}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>順位</th>
                  <th>オススメ度</th>
                  <th>サービス</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {formValues.map((item, i) => {
                  console.log(item)
                  return (<tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      <Form.Control
                        name="priority"
                        onChange={handleChange(i)}
                        type="number"
                        value={item.priority}
                      />
                      <ErrorMessage errors={errors} errorKey="priority" index={i}/>
                    </td>
                    <td>
                      <Form.Select name="site_service_id" onChange={handleChange(i)} value={item.site_service_id}>
                        <option value="">選択</option>
                        {siteServices.map(item => 
                          <option key={item.id} value={item.id}>{item.service.name}</option>
                        )}
                      </Form.Select>
                    </td>
                    <td>
                      <Button onClick={handleClickRemove(i)} type="button" variant="outline-danger">&times;</Button>
                    </td>
                  </tr>)}
                )}
              </tbody>
            </Table>
            <div className="d-grid gap-2 mb-3">
              <Button onClick={handleClickAdd} type="button" variant="outline-success">追加</Button>
            </div>
            <div className="d-grid gap-2">
              <Button type="submit" variant="primary">保存</Button>
            </div>
          </Form>
        </FormContainer>
      }
    </div>
  )
}

export default RankingSiteServiceForm