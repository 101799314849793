import {useEffect, useState}  from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {useNavigate, useParams} from 'react-router-dom'
import ErrorMessage from './ui/ErrorMessage'
import FormContainer from './ui/FormContainer'
import Headline from './ui/Headline'
import ImageField from './ui/ImageField'
import PrefectureField from './ui/PrefectureField'
import SwitchField from './ui/SwitchField'
import JsonSchemaDataListForm from './ui/JsonSchemaDataListForm'
import {parseErrors} from '../api'
import {readServices} from '../api/services'
import {createShop, readShop, updateShop} from '../api/shops'

const INITIAL_FORM_VALUES = {
  service_id: '',
  name: '',
  prefecture: '',
  area: '',
  postcode: '',
  address: '',
  weekday_open: '',
  weekday_closed: '',
  weekday_notes: '',
  weekend_open: '',
  weekend_closed: '',
  weekend_notes: '',
  parking: '',
  beds: '',
  image_url: '',
  is_available: 'true',
}

const ShopForm = () => {
  const [services, setServices] = useState([])
  const [errors, setErrors] = useState()
  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [sites, setSites] = useState([])

  let navigate = useNavigate()
  const {id} = useParams()

  const handleChange = e => {
    const {target: {checked, name, value}} = e

    let v = value
    if(name == 'is_available') {
      v = checked
    }

    setFormValues({...formValues, [name]: v})
  }

  const handleSubmit = async e => {
    e.preventDefault()

    setIsButtonDisabled(true)

    try {
      console.log(formValues)
      let newFormValues = {}
      Object.keys(formValues).map(k => {
        if(formValues[k] === '') {
          newFormValues[k] = null
        }
        else {
          newFormValues[k] = formValues[k]
        }
      })

      if(id) {
        await updateShop(id, newFormValues)
      }
      else {
        await createShop(newFormValues)
      }
      navigate('/shops', {replace: true})
    }
    catch(err) {
      setErrors(parseErrors(err))
    }

    setIsButtonDisabled(false)
  }

  const initForm = async id => {
    try {
      let newFormValues = !id ? INITIAL_FORM_VALUES : await readShop(id)
      Object.keys(newFormValues).map(k => {
        if(newFormValues[k] == null) {
          newFormValues[k] = ''
        }
      })
      setFormValues(newFormValues)
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
    finally {
      setIsReady(true)
    }
  }

  const initRelations = async () => {
    try {
      const r = await readServices({
        fields: ['id', 'name'],
        limit: 9999,
        page: 1,
      })

      setServices(r.data)
    }
    catch(err) {
      console.log(err)
      setErrors(parseErrors(err))
    }
  }

  useEffect(() => {
    initForm(id)
    initRelations()
  }, [id])

  return (
    <div>
      <Headline>店舗{id ? '編集' : '追加'}</Headline>
      {isReady &&
        <FormContainer>
          <ErrorMessage errors={errors}/>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>共通サービス</Form.Label>
              <Form.Select name="service_id" onChange={handleChange} value={formValues.service_id}>
                <option value="">選択</option>
                {services.map(item => 
                  <option key={item.id} value={item.id}>{item.name}</option>
                )}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>店舗名</Form.Label>
              <Form.Control
                name="name"
                onChange={handleChange}
                placeholder="銀座店"
                type="text"
                value={formValues.name}
              />
              <ErrorMessage errors={errors} errorKey="name"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>都道府県</Form.Label>
              <PrefectureField
                name="prefecture"
                onChange={handleChange}
                value={formValues.prefecture}
              />
              <ErrorMessage errors={errors} errorKey="prefecture"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>エリア</Form.Label>
              <Form.Control
                name="area"
                onChange={handleChange}
                placeholder="銀座"
                type="text"
                value={formValues.area}
              />
              <ErrorMessage errors={errors} errorKey="area"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>郵便番号</Form.Label>
              <Form.Control
                name="postcode"
                onChange={handleChange}
                placeholder="1000000"
                type="text"
                value={formValues.postcode}
              />
              <ErrorMessage errors={errors} errorKey="postcode"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>住所</Form.Label>
              <Form.Control
                name="address"
                onChange={handleChange}
                placeholder="東京都中央区京橋3-9-4"
                type="text"
                value={formValues.address}
              />
              <ErrorMessage errors={errors} errorKey="address"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>開店（平日）</Form.Label>
              <Form.Control
                name="weekday_open"
                onChange={handleChange}
                type="time"
                value={formValues.weekday_open}
              />
              <ErrorMessage errors={errors} errorKey="weekday_open"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>閉店（平日）</Form.Label>
              <Form.Control
                name="weekday_closed"
                onChange={handleChange}
                type="time"
                value={formValues.weekday_closed}
              />
              <ErrorMessage errors={errors} errorKey="weekday_closed"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>平日営業時間の備考</Form.Label>
              <Form.Control
                name="weekday_notes"
                onChange={handleChange}
                placeholder="店舗によります。"
                type="text"
                value={formValues.weekday_notes}
              />
              <ErrorMessage errors={errors} errorKey="weekday_notes"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>開店（休日）</Form.Label>
              <Form.Control
                name="weekend_open"
                onChange={handleChange}
                type="time"
                value={formValues.weekend_open}
              />
              <ErrorMessage errors={errors} errorKey="weekend_open"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>閉店（休日）</Form.Label>
              <Form.Control
                name="weekend_closed"
                onChange={handleChange}
                type="time"
                value={formValues.weekend_closed}
              />
              <ErrorMessage errors={errors} errorKey="weekend_closed"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>休日営業時間の備考</Form.Label>
              <Form.Control
                name="weekend_notes"
                onChange={handleChange}
                placeholder="店舗によります。"
                type="text"
                value={formValues.weekend_notes}
              />
              <ErrorMessage errors={errors} errorKey="weekend_notes"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>駐車場</Form.Label>
              <Form.Control
                name="parking"
                onChange={handleChange}
                placeholder="あり"
                type="text"
                value={formValues.parking}
              />
              <ErrorMessage errors={errors} errorKey="parking"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>ベッド数</Form.Label>
              <Form.Control
                name="beds"
                onChange={handleChange}
                placeholder="5"
                type="number"
                value={formValues.beds}
              />
              <ErrorMessage errors={errors} errorKey="beds"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>画像</Form.Label>
              <ImageField
                name="image_url"
                onChange={handleChange}
                value={formValues.image_url}
              />
              <ErrorMessage errors={errors} errorKey="image_url"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>表示</Form.Label>
              <SwitchField
                checked={formValues.is_available === true}
                name="is_available"
                onChange={handleChange}
                value={formValues.is_available}
              />
              <ErrorMessage errors={errors} errorKey="is_available"/>
            </Form.Group>
            <div className="d-grid gap-2">
              <Button disabled={isButtonDisabled} type="submit" variant="primary">保存</Button>
            </div>
          </Form>
        </FormContainer>
      }
    </div>
  )
}

export default ShopForm
