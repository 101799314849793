import {Fragment} from 'react'

const toTwoDigits = n => {
  return n >= 10 ? n.toString() : `0${n}`
}

const Datetime = ({date, format}) => {
  let dt = typeof date == 'string' ? new Date(date) : date

  if(format) {
    throw new Error('Not implemented.')
  }

  return <Fragment>{`${dt.getFullYear()}-${toTwoDigits(dt.getMonth() + 1)}-${toTwoDigits(dt.getDate())} ${toTwoDigits(dt.getHours())}:${toTwoDigits(dt.getMinutes())}:${toTwoDigits(dt.getSeconds())}`}</Fragment>
}

export default Datetime
