import {API} from 'aws-amplify'
import {DEFAULT_LIMIT, getAuthorizationHeader, getVersion} from '../'

const createService = async body => {
  const headers = await getAuthorizationHeader()

  return API.post('datsururin', `v1/services`, {
    body,
    headers,
    response: true
  })
}

const readService = async id => {
  const headers = await getAuthorizationHeader()
  
  return API.get('datsururin', `v1/services/${id}?v=${getVersion()}`, {
    headers
  })
}

const readServices = async ({fields, ids, page, limit = DEFAULT_LIMIT}) => {
  const headers = await getAuthorizationHeader()

  let queryStringParameters = {
    order: 'DESC',
    orderby: 'id',
    limit,
    page,
    v: getVersion(),
  }
  if(fields) {
    queryStringParameters.fields = fields
  }
  if(ids) {
    queryStringParameters.ids = ids
  }

  return API.get('datsururin', 'v1/services', {
    headers, queryStringParameters
  })
}

const updateService = async (id, body) => {
  const headers = await getAuthorizationHeader()

  return API.put('datsururin', `v1/services/${id}`, {
    body,
    headers,
    response: true
  })
}

const createServicesJsonSchema = async (id, body) => {
  const headers = await getAuthorizationHeader()

  return API.post('datsururin', `v1/services/${id}/json-schemas`, {
    body,
    headers,
    response: true
  })
}

const updateServicesJsonSchema = async (id, body, servicesJsonSchemaId) => {
  const headers = await getAuthorizationHeader()

  return API.put('datsururin', `v1/services/${id}/json-schemas/${servicesJsonSchemaId}`, {
    body,
    headers,
    response: true
  })
}

export {
  createService,
  readService,
  readServices,
  updateService,
  createServicesJsonSchema,
  updateServicesJsonSchema,
}
