import {useState} from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Stack from 'react-bootstrap/Stack'
import CloudFrontDistributionField from './CloudFrontDistributionField'
import SuccessMessage from './SuccessMessage'
import ErrorMessage from './ErrorMessage'
import {parseErrors} from '../../api'
import {invalidateCloudFrontDistribution} from '../../api/cloud-front-distributions'

const initialFormValues = {
  items: ['',],
  cloud_front_distribution_id: ''
}

const InvalidationForm = () => {
  const [errors, setErrors] = useState()
  const [formValues, setFormValues] = useState(initialFormValues)
  const [successMessage, setSuccessMessage] = useState()

  const handleChange = e => {
    const {target: {name, value}} = e
    setFormValues({...formValues, [name]: value})
  }

  const handleChangeItem = index => e => {
    const newItems = formValues.items.map((item, i) => i == index ? e.target.value : item)
    setFormValues({...formValues, items: newItems})
  }

  const handleClickAdd = e => {
    setFormValues({...formValues, items: formValues.items.concat(['',])})
  }

  const handleClickRemove = index => e => {
    const newItems = formValues.items.filter((item, i) => i != index)
    setFormValues({...formValues, items: newItems})
  }

  const handleSubmit = async e => {
    setErrors()
    setSuccessMessage()

    e.preventDefault()

    try {
      await invalidateCloudFrontDistribution(formValues)
      setSuccessMessage('キャッシュ削除を開始しました。しばらく待ってから該当ページにアクセスしてください。')
      setFormValues(initialFormValues)
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {successMessage &&
        <SuccessMessage message={successMessage}/>
      }
      <ErrorMessage errors={errors}/>
      <Stack direction="horizontal">
        <Stack className="ms-auto align-items-start" direction="horizontal" gap={2}>
          <CloudFrontDistributionField
            label={false}
            onChange={handleChange}
            value={formValues.cloud_front_distribution_id}
          />
          <div>
            {formValues.items.map((item, i) => {
              return (
                <Form.Group className="mb-3" key={i}>
                  <Stack direction="horizontal" gap={2}>
                    <Form.Control
                        className="me-auto"
                        name="item"
                        onChange={handleChangeItem(i)}
                        placeholder={`/categories/iryou*`}
                        type="text"
                        value={item}
                      />
                    {i != 0 &&
                      <Button onClick={handleClickRemove(i)} type="button" variant="danger">&times;</Button>
                    }
                  </Stack>
                  <ErrorMessage errors={errors} errorKey="name"/>
                </Form.Group>
              )
            })}
          </div>
          <Button onClick={handleClickAdd} type="button" variant="success">+</Button>
          <Button type="submit" variant="warning">キャッシュを削除</Button>
        </Stack>
      </Stack>
    </Form>
  )
}

export default InvalidationForm