import Prism from 'prismjs'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import {Editable, Slate, withReact} from 'slate-react'
import {createEditor, Text} from 'slate'
import {withHistory} from 'slate-history'
import {css} from '@emotion/css'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-markup'

const CodeField = ({label, language, name, onChange, value: initialValue}) => {
  const [value, setValue] = useState()

  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  const decorate = ([node, path]) => {
    const ranges = []
    if (!Text.isText(node)) {
      return ranges
    }
    const tokens = Prism.tokenize(node.text, Prism.languages[language])
    let start = 0

    for (const token of tokens) {
      const length = getLength(token)
      const end = start + length

      if (typeof token !== 'string') {
        ranges.push({
          [token.type]: true,
          anchor: { path, offset: start },
          focus: { path, offset: end },
        })
      }

      start = end
    }

    return ranges
  }

  const handleChange = value => {
    setValue(value)

    let content = ''
    for(let v of value) {
      for(let child of v.children) {
        content += child.text
      }
    }
    onChange({target: {name, value: content}})
  }

  const handleKeyDown = e => {
    if(e.key !== 'Tab') {
      return
    }

    e.preventDefault()

    editor.insertText('  ')
  }

  useEffect(() => {
    let val = ''
    try {
      val = initialValue ? JSON.stringify(JSON.parse(initialValue), null, 2) : ''  
    }
    catch(err) {
      val = initialValue.toString()
    }

    let vals = val.split('\n').map(text => {
      return {
        type: 'paragraph',
        children: [
          {
            text,
          },
        ],
      }
    })
    setValue(vals)

  }, [initialValue])

  if(!value) {
    return null
  }

  return (
    <div>
      {label &&
        <Form.Label>{label}</Form.Label>
      }
      <Card>
        <Card.Body>
          <Slate
            editor={editor}
            onChange={handleChange}
            value={value}
          >
            <Editable
              decorate={decorate}
              onKeyDown={handleKeyDown}
              renderLeaf={renderLeaf}
            />
          </Slate>
        </Card.Body>
      </Card>
    </div>
  )
}

const getLength = token => {
  if (typeof token === 'string') {
    return token.length
  } else if (typeof token.content === 'string') {
    return token.content.length
  } else {
    return token.content.reduce((l, t) => l + getLength(t), 0)
  }
}

// different token types, styles found on Prismjs website
const Leaf = ({ attributes, children, leaf }) => {
  return (
    <span
      {...attributes}
      className={css`
            font-family: monospace;
            background: hsla(0, 0%, 100%, .5);

        ${leaf.comment &&
          css`
            color: slategray;
          `}

        ${(leaf.operator || leaf.url) &&
          css`
            color: #9a6e3a;
          `}
        ${leaf.keyword &&
          css`
            color: #07a;
          `}
        ${(leaf.variable || leaf.regex) &&
          css`
            color: #e90;
          `}
        ${(leaf.number ||
          leaf.boolean ||
          leaf.tag ||
          leaf.constant ||
          leaf.symbol ||
          leaf['attr-name'] ||
          leaf.selector) &&
          css`
            color: #905;
          `}
        ${leaf.punctuation &&
          css`
            color: #999;
          `}
        ${(leaf.string || leaf.char) &&
          css`
            color: #690;
          `}
        ${(leaf.function || leaf['class-name']) &&
          css`
            color: #dd4a68;
          `}
        `}
    >
      {children}
    </span>
  )
}

export default CodeField
