import {API} from 'aws-amplify'
import {DEFAULT_LIMIT, getAuthorizationHeader, getVersion} from '../'

const createPage = async body => {
  const headers = await getAuthorizationHeader()

  return API.post('datsururin', `v1/pages`, {
    body,
    headers,
    response: true
  })
}

const readPage = async id => {
  const headers = await getAuthorizationHeader()
  
  return API.get('datsururin', `v1/pages/${id}?v=${getVersion()}`, {
    headers
  })
}

const readPages = async ({fields, page, limit = DEFAULT_LIMIT}) => {
  const headers = await getAuthorizationHeader()

  const queryStringParameters = {
    order: 'DESC',
    orderby: 'id',
    limit,
    page,
    v: getVersion(),
  }
  if(fields) {
    queryStringParameters.fields = fields
  }

  return API.get('datsururin', 'v1/pages', {
    headers, queryStringParameters
  })
}

const updatePage = async (id, body) => {
  const headers = await getAuthorizationHeader()

  return API.put('datsururin', `v1/pages/${id}`, {
    body,
    headers,
    response: true
  })
}

const createPagesJsonSchema = async (id, body) => {
  const headers = await getAuthorizationHeader()

  return API.post('datsururin', `v1/pages/${id}/json-schemas`, {
    body,
    headers,
    response: true
  })
}

const updatePagesJsonSchema = async (id, body, pagesJsonSchemaId) => {
  const headers = await getAuthorizationHeader()

  return API.put('datsururin', `v1/pages/${id}/json-schemas/${pagesJsonSchemaId}`, {
    body,
    headers,
    response: true
  })
}

export {
  createPage,
  readPage,
  readPages,
  updatePage,
  createPagesJsonSchema,
  updatePagesJsonSchema,
}
