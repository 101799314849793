import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import {LinkContainer} from 'react-router-bootstrap'

const NotFound = () => {
  return (
      <Container>
        <Alert className="mt-4" variant="danger">
          URL が見つかりませんでした。
          <LinkContainer to="/">
            <Alert.Link>トップに戻る</Alert.Link>
          </LinkContainer>
        </Alert>
      </Container>
  )
}

export default NotFound
