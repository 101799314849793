import {useEffect, useState}  from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {useNavigate, useParams} from 'react-router-dom'
import ErrorMessage from './ui/ErrorMessage'
import FormContainer from './ui/FormContainer'
import Headline from './ui/Headline'
import JsonSchemaDataListForm from './ui/JsonSchemaDataListForm'
import {parseErrors} from '../api'
import {createPage, readPage, updatePage} from '../api/pages'
import {readSites} from '../api/sites'
import {DATA_TYPE_PAGE} from '../constants/json-schemas'

const INITIAL_FORM_VALUES = {
  name: '',
  slug: '',
  site_id: '',
  // json_schema_id: '',
  // json_schema_data: '',
}

const PageForm = () => {
  const [errors, setErrors] = useState()
  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES)
  const [isReady, setIsReady] = useState(false)
  // const [jsonSchemas, setJsonSchemas] = useState([])
  const [sites, setSites] = useState([])

  let navigate = useNavigate()
  const {id} = useParams()

  const handleChange = e => {
    const {target: {name, value}} = e
    setFormValues({...formValues, [name]: value})
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      if(id) {
        await updatePage(id, formValues)
      }
      else {
        await createPage(formValues)
      }
      navigate('/pages', {replace: true})
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  const initForm = async id => {
    try {
      const newFormValues = !id ? INITIAL_FORM_VALUES : await readPage(id)
      setFormValues(newFormValues)
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
    finally {
      setIsReady(true)
    }
  }

  const initRelations = async () => {
    try {
      const rs = await Promise.all([
        readSites(1, 9999),
      ])
      // setJsonSchemas(rs[0].data)
      setSites(rs[0].data)
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  useEffect(() => {
    initRelations()
  }, [])

  useEffect(() => {
    initForm(id)
  }, [id])

  return (
    <div>
      <Headline>ページ{id ? '編集' : '追加'}</Headline>
      {isReady &&
        <FormContainer>
          <ErrorMessage errors={errors}/>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>名前</Form.Label>
              <Form.Control
                name="name"
                onChange={handleChange}
                placeholder="医療脱毛推しページ"
                type="text"
                value={formValues.name}
              />
              <ErrorMessage errors={errors} errorKey="name"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>スラッグ</Form.Label>
              <Form.Control
                name="slug"
                onChange={handleChange}
                placeholder="iryou"
                type="text"
                value={formValues.slug}
              />
              <ErrorMessage errors={errors} errorKey="slug"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>サイト</Form.Label>
              <Form.Select name="site_id" onChange={handleChange} value={formValues.site_id}>
                <option value="">選択</option>
                {sites.map(item => 
                  <option key={item.id} value={item.id}>{item.name}</option>
                )}
              </Form.Select>
            </Form.Group>
            <div className="d-grid gap-2">
              <Button type="submit" variant="primary">保存</Button>
            </div>
          </Form>
          {id &&
            <Form className="mt-4">
              <JsonSchemaDataListForm
                dataType={DATA_TYPE_PAGE}
                id={id}
                siteId={formValues.site_id}
                values={formValues.json_schemas}
              />
            </Form>
          }
        </FormContainer>
      }
    </div>
  )
}

export default PageForm
