import {useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import {useNavigate, useParams} from 'react-router-dom'
import FormContainer from './ui/FormContainer'
import Headline from './ui/Headline'
import ErrorMessage from './ui/ErrorMessage'
import PrefectureField from './ui/PrefectureField'
import {parseErrors} from '../api'
import {batchUpsertShopAccesses, readShop, readShopAccesses} from '../api/shops'

const INITIAL_FORM_VALUES = {
  site_service_id: '',
  priority: '',
}

const MINUTES_TYPES = ['徒歩', 'バス']

const ShopAccessForm = () => {
  const [errors, setErrors] = useState()
  const [formValues, setFormValues] = useState()
  // const [shop, setShop] = useState()
  const [shopAccesses, setShopAccesses] = useState()

  let navigate = useNavigate()
  const {id} = useParams()

  const handleChange = index => e => {
    const {target: {name, value}} = e

    const newFormValues = {...formValues[index], [name]: value}
    setFormValues(formValues.map((item, i) => i === index ? newFormValues : item))
  }

  const handleClickAdd = e => {
    setFormValues(formValues.concat(INITIAL_FORM_VALUES))
  }

  const handleClickRemove = index => e => {
    setFormValues(formValues.filter((item, i) => i !== index))
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      await batchUpsertShopAccesses(id, formValues.map(item => ({...item, shop_id: id})))
      navigate('/shops', {replace: true})
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  const initForm = async id => {
    try {
      const rs = await Promise.all([
        readShopAccesses(id),
        // readShop(id),
      ])

      setFormValues(rs[0])
      // setShop(rs[1])
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  useEffect(() => {
    initForm(id)
  }, [id])

  return (
    <div>
      <Headline>店舗アクセス{id ? '編集' : '追加'}</Headline>
      <ErrorMessage errors={errors}/>
      {Array.isArray(formValues) &&
        <div>
          <Form onSubmit={handleSubmit}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>都道府県</th>
                  <th>駅</th>
                  <th>ライン</th>
                  <th>駅から</th>
                  <th>分数</th>
                  <th>分数のテキスト</th>
                </tr>
              </thead>
              <tbody>
                {formValues.map((item, i) => 
                  <tr key={i}>
                    <td>
                      <PrefectureField
                        name="prefecture"
                        onChange={handleChange(i)}
                        value={item.prefecture}
                      />
                    </td>
                    <td>
                      <Form.Control
                        name="train_station"
                        onChange={handleChange(i)}
                        placeholder="東京駅"
                        type="text"
                        value={item.train_station}
                      />
                      <ErrorMessage errors={errors} errorKey="train_station" index={i}/>
                    </td>
                    <td>
                      <Form.Control
                        name="train_line"
                        onChange={handleChange(i)}
                        placeholder="JR中央線"
                        type="text"
                        value={item.train_line}
                      />
                      <ErrorMessage errors={errors} errorKey="train_line" index={i}/>
                    </td>
                    <td>
                      <Form.Select name="minutes_type" onChange={handleChange(i)} value={item.minutes_type}>
                        <option value="">選択</option>
                        {MINUTES_TYPES.map(item => 
                          <option key={item} value={item}>{item}</option>
                        )}
                      </Form.Select>
                      <ErrorMessage errors={errors} errorKey="minutes_type" index={i}/>
                    </td>
                    <td>
                      <Form.Control
                        name="minutes"
                        onChange={handleChange(i)}
                        placeholder="3"
                        type="number"
                        value={item.minutes}
                      />
                      <ErrorMessage errors={errors} errorKey="minutes" index={i}/>
                    </td>
                    <td>
                      <Form.Control
                        name="minutes_text"
                        onChange={handleChange(i)}
                        placeholder="フェリーで15分"
                        type="text"
                        value={item.minutes_text}
                      />
                      <ErrorMessage errors={errors} errorKey="minutes_text" index={i}/>
                    </td>
                    <td>
                      <Button onClick={handleClickRemove(i)} type="button" variant="outline-danger">&times;</Button>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <div className="d-grid gap-2 mb-3">
              <Button onClick={handleClickAdd} type="button" variant="outline-success">追加</Button>
            </div>
            <div className="d-grid gap-2">
              <Button type="submit" variant="primary">保存</Button>
            </div>
          </Form>
        </div>
      }
    </div>
  )
}

export default ShopAccessForm