import React, {Fragment, useEffect, useState} from 'react'
import Form from 'react-bootstrap/Form'
import ErrorMessage from './ErrorMessage'
import {parseErrors} from '../../api'
import {readJsonSchemas} from '../../api/json-schemas'

const JsonSchemaSelect = ({dataType, disabled, errors, onChange, value}) => {
  const [formErrors, setFormErrors] = useState()
  const [jsonSchemas, setJsonSchemas] = useState([])

  const initForm = async () => {
    try {
      const r = await readJsonSchemas({dataType, page: 1, limit: -1})
      setJsonSchemas(r.data)
    }
    catch(err) {
      setFormErrors(parseErrors(err))
    }
  }

  useEffect(() => {
    initForm()
  }, [dataType])

  return (
    <Fragment>
      <ErrorMessage errors={formErrors}/>
      <Form.Label>JSON Schema</Form.Label>
      <Form.Select disabled={disabled === true} name="json_schema_id" onChange={onChange} value={value}>
        <option value="">選択</option>
        {jsonSchemas.map(item => 
          <option key={item.id} value={item.id}>{item.name}</option>
        )}
      </Form.Select>
    </Fragment>
  )
}

export default JsonSchemaSelect
