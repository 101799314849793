import {useEffect, useState} from 'react'
import Form from 'react-bootstrap/Form'
import {readCloudFrontDistributions} from '../../api/cloud-front-distributions'

const CloudFrontDistributionField = ({label = true, name = 'cloud_front_distribution_id', onChange, value}) => {
  const [cfds, setCfds] = useState([])

  const update = async () => {
    try {
      const r = await readCloudFrontDistributions({fields: ['cloud_front_distribution_id', 'name'], limit: -1})
      setCfds(r.data)
    }
    catch(err) {
      console.log(err)
    }
  }

  useEffect(() => {
    update()
  }, [name])

  return (
    <Form.Group className="mb-3">
      {label === true && <Form.Label>CF</Form.Label>}
      <Form.Select name={name} onChange={onChange} value={value}>
        <option value="">選択</option>
        {cfds.map(item => 
          <option key={item.cloud_front_distribution_id} value={item.cloud_front_distribution_id}>{item.name}</option>
        )}
      </Form.Select>
    </Form.Group>
  )
}

export default CloudFrontDistributionField