import {useEffect, useState} from 'react'
import Table from 'react-bootstrap/Table'
import {Link, useSearchParams} from 'react-router-dom'
import ErrorMessage from './ui/ErrorMessage'
import Datetime from './ui/Datetime'
import Headline from './ui/Headline'
import InvalidationForm from './ui/InvalidationForm'
import Pagination from './ui/Pagination'
import {parseErrors} from '../api'
import {readMachines} from '../api/machines'
import {readServices} from '../api/services'

const MachineList = ({user}) => {
  const [errors, setErrors] = useState([])
  const [page, setPage] = useState()
  const [pagination, setPagination] = useState({})
  const [servicesMap, setServicesMap] = useState({})

  const [searchParams] = useSearchParams()

  const init = async () => {
    // const r = await readSites(1, 9999)
    
    // try {
    //   let sm = {}
    //   for(let d of r.data) {
    //     sm[d.id.toString()] = d
    //   }
    //   // setSitesMap(sm)
    // }
    // catch(err) {
    //   setErrors(parseErrors(err))
    // }
  }

  const paginate = async page => {
    const r = await readMachines({page: page || 1})
    try {
      setPagination(r)
    }
    catch(err) {
      setErrors(parseErrors(err))
    }
  }

  const initRelations = async machines => {
    let serviceIds = []
    for(let machine of machines) {
      if(!serviceIds.includes(machine.service_id)) {
        serviceIds.push(machine.service_id)
      }
    }

    const r = await readServices({
      ids: serviceIds,
      limit: -1,
      page: 1
    })

    let newServicesMap = {}
    for(let d of r.data) {
      newServicesMap[d.id.toString()] = d
    }

    setServicesMap(newServicesMap)
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    let newPage = Number(searchParams.get('page'))
    if(isNaN(newPage) || newPage === 0) {
      newPage = 1
    }
    setPage(newPage)

    paginate(newPage)
  }, [searchParams])

  useEffect(() => {
    if(pagination.data) {
      initRelations(pagination.data)
    }
  }, [pagination])

  return (
    <div>
      <Headline>脱毛機一覧</Headline>
      <InvalidationForm/>
      <ErrorMessage errors={errors}/>
      {pagination.data &&
        <Table striped bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>共通サービス</th>
              <th>機械名</th>
              <th>タイプ</th>
              <th>痛み</th>
              <th>特徴</th>
              <th>作成日時</th>
              <th>更新日時</th>
              <th>アクション</th>
            </tr>
          </thead>
          <tbody>
            {pagination.data.map(item => {
              const service = servicesMap[item.service_id.toString()]
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{service ? service.name : ''}</td>
                  <td>{item.name}</td>
                  <td>{item.machine_type}</td>
                  <td>{item.pain}</td>
                  <td>{item.features}</td>
                  <td><Datetime date={item.created_at}/></td>
                  <td><Datetime date={item.updated_at}/></td>
                  <td><Link to={`/machines/${item.id}`}>編集</Link></td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      }
      <Pagination page={page} pages={pagination.pages}/>
    </div>
  )
}

export default MachineList
